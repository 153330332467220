import React from "react";

class PortfolioEntry extends React.Component {
  constructor(props) {
    super(props);
  }

  renderDesktop = () => {
    const rowClassName = "row justify-content-center align-items-center";

    return (
        <div className={rowClassName}>
          <div className="col-3">
            <a className="App-link" href={this.props.projectLink} target="_blank" rel="noreferrer">
              <img src={process.env.PUBLIC_URL + this.props.projectAsset} alt=""/>
            </a>
          </div>
          <div className="col-3">
            <p> {this.props.project} </p>
          </div>
          <div className="col">
            <p> {this.props.stack} </p>
          </div>
        </div>
    )
  }

  renderMobile() {
    return (
        <div>
          <a className="App-link" href={this.props.projectLink} target="_blank" rel="noreferrer">
            <img src={process.env.PUBLIC_URL + this.props.projectAsset} alt=""/>
          </a>
          <p><strong>Project:</strong> {this.props.project} </p>
          <p><strong>Tech Stack:</strong> {this.props.stack} </p>
        </div>
    )
  }

  render() {
    return (
        <div>
          <br/>
          <div className="d-none d-md-block">
            {this.renderDesktop()}
          </div>
          <div className="d-block d-md-none">
            {this.renderMobile()}
          </div>
        </div>
    );
  }
}

export default PortfolioEntry;

