import React from 'react';

import {NavLink} from 'react-router-dom';

const Navigation = () => {
  return (
      <div>
        <nav className="navbar navbar-dark bg-dark navbar-expand-lg">
          <div className="container-fluid">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"/>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/">Home</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/portfolio">Portfolio</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/library">Library</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/nvmvs">NVMVS</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
  );
}

export default Navigation;
