import React from "react";
import ReactDOM from "react-dom"

const PayPalButton = window.paypal.Buttons.driver("react", {React, ReactDOM});

class PaypalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      loading: true,
      paid: false
    };

    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    this.setState({loading: false, showButtons: true});
  }

  createOrder = (data, actions) => {
    let purchaseItems = []
    let totalValue = 0

    for (const product of this.props.products) {
      purchaseItems.push({
        name: product.name,
        unit_amount: {value: product.price, currency_code: this.props.currency},
        quantity: product.quantity
      })
      totalValue = totalValue + product.price * product.quantity;
    }

    const purchaseUnits = [{
      amount: {
        value: totalValue,
        currency_code: this.props.currency,
        breakdown: {
          item_total: {value: totalValue, currency_code: this.props.currency}
        }
      },
      items: purchaseItems
    }]

    return actions.order.create({
      purchase_units: purchaseUnits
    });
  };

  onApprove = (data, actions) => {
    actions.order.capture().then(() => {
      const paymentData = {
        payerID: data.payerID,
        orderID: data.orderID
      };
      console.log("Payment Approved: ", paymentData);
      this.setState({showButtons: false, paid: true});
    });
  };

  render() {
    const {showButtons, loading, paid} = this.state;

    const renderedProducts = this.props.products.map((product, i) => {
      return (
          <h5 key={i}>
            Name:{product.name}, Individual price:{product.price} , Quantity:{product.quantity}
          </h5>
      )
    })

    const renderedProductsTotal = (
        <h5>
          {this.props.products
              .map(product => product.price * product.quantity)
              .reduce((a, b) => a + b, 0)}
        </h5>
    )

    return (
        <div className="main">
          {loading && (
              <h1>$Loading$</h1>
          )}

          {showButtons && (
              <div>
                <div>
                  {renderedProducts}
                  {renderedProductsTotal}
                </div>

                <PayPalButton
                    createOrder={(data, actions) => this.createOrder(data, actions)}
                    onApprove={(data, actions) => this.onApprove(data, actions)}
                />
              </div>
          )}

          {paid && (
              <div className="main">
                <h2>
                  Congrats! you just paid for that picture. Work a little harder and
                  you'll be able to afford the car itself{" "}
                  <span role="img" aria-label="emoji"> {" "} 😉 </span>
                </h2>
              </div>
          )}
        </div>
    );
  }
}

export default PaypalButton;
