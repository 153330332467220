import './Home.css';
import React from 'react';
import Navigation from "../components/navigation/Navigation";

class Home extends React.Component {

  homeButton = (link, name) => {
    return (
        <a role="button" className="col btn btn-light home-btn"
           href={link} target="_blank" rel="noreferrer">
          <img src={process.env.PUBLIC_URL + "assets/" + name + "-logo.png"} alt={name}/>
        </a>
    )
  }

  renderDesktop = () => {
    return (
        <div className="row">
          {this.homeButton("https://www.linkedin.com/in/rafael-p-marques", "linkedin")}
          <div className="col"/>
          {this.homeButton("https://www.xing.com/profile/Rafael_Marques8", "xing")}
          <div className="col"/>
          {this.homeButton("https://github.com/RPMXH", "github")}
        </div>
    )
  }

  renderMobile() {
    return (
        <div>
          {this.homeButton("https://www.linkedin.com/in/rafael-p-marques", "linkedin")}
          <p/>
          {this.homeButton("https://www.xing.com/profile/Rafael_Marques8", "xing")}
          <p/>
          {this.homeButton("https://github.com/RPMXH", "github")}
        </div>
    )
  }


  render() {
    return (
        <div className="App">
          <Navigation/>
          <header className="App-header">
            <h1>Hello, my name is Rafael Marques</h1>

            <h2>This my personal, projects & experiments page.</h2>

            <p/>

            <div className="d-none d-md-block">
              {this.renderDesktop()}
            </div>

            <div className="d-block d-md-none">
              {this.renderMobile()}
            </div>
          </header>
        </div>
    );
  }
}


export default Home;
