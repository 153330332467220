import React from 'react';
import NvmvsNavigation from "../../components/navigation/NvmvsNavigation";
import NvmvsBanner from "../../components/NvmvsBanner";

const NvmvsProducts = () => {
  return (
      <div className="container">
        <NvmvsBanner/>
        <NvmvsNavigation/>
        <h1>Products</h1>
        {/*TODO - toast message when client add something to cart*/}
      </div>
  );
}

export default NvmvsProducts;
