import React from 'react';
import NvmvsNavigation from "../../components/navigation/NvmvsNavigation";
import NvmvsBanner from "../../components/NvmvsBanner";

const NvmvsContacts = () => {
  return (
      <div className="container">
        <NvmvsBanner/>
        <NvmvsNavigation/>
        <h1>Contacts</h1>
        <h2>MAP "PLACEHOLDER"</h2>
        <p>R. Direita,213,4450-652 Matosinhos</p>
        <p>Portugal</p>
        <p>Compra / Venda</p>
      </div>
  );
}

export default NvmvsContacts;
