import React from 'react';
import Navigation from "../../components/navigation/Navigation";
import NvmvsNavigation from "../../components/navigation/NvmvsNavigation";
import '../../nvmvs.css'
import NvmvsBanner from "../../components/NvmvsBanner";

class NvmvsHome extends React.Component {
  constructor(props) {
    super(props);
  }

  commonContent = () => {
    return [
      <Navigation/>,
      <NvmvsBanner/>,
      <NvmvsNavigation/>
    ]
  }

  placeholdersContent = () => {
    return [
      <h2>Find us in Contacts "PLACEHOLDER"</h2>,
      <h2>Product Suggestions "PLACEHOLDER"</h2>
    ]
  }

  renderDesktop = () => {
    return (
        <div className="container">
          {this.commonContent()}
          <div className="d-flex justify-content-evenly mt-2">
            <img src={process.env.PUBLIC_URL + '/assets/mask-face-guard.png'}
                 alt="Acesso ao estabelecimento apenas com PROTECÇÃO" className="col-4"/>
          </div>
          {this.placeholdersContent()}
        </div>
    )
  }

  renderMobile() {
    return (
        <div>
          {this.commonContent()}
          <div className="d-flex justify-content-evenly mt-2">
            <img src={process.env.PUBLIC_URL + '/assets/mask-face-guard.png'}
                 alt="Acesso ao estabelecimento apenas com PROTECÇÃO" className="col-9"/>
          </div>
          {this.placeholdersContent()}
        </div>
    )
  }

  render() {
    return (
        <div>
          <div className="d-none d-md-block">
            {this.renderDesktop()}
          </div>
          <div className="d-block d-md-none">
            {this.renderMobile()}
          </div>
        </div>
    );
  }
}

export default NvmvsHome;
