import './CustomToggle.css'
import React from "react";


const CustomToggle = () => {
  const styleOn = {
    fill: '#4ADE80',
    transform: '',
    '-ms-filter': ''
  }
  const styleOff = {
    fill: '#F87171',
    transform: '',
    '-ms-filter': ''
  }


  return (
      <div>
        <label className="switch">
          <input type="checkbox" className="checkbox"/>
          <span className="toggle-thumb">

          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" style={styleOn}>
            <path d="M10 15.586L6.707 12.293 5.293 13.707 10 18.414 19.707 8.707 18.293 7.293z"/>
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" style={styleOff}>
            <path
                d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z"/>
          </svg>

     </span>
        </label>
      </div>
  );
}

export default CustomToggle
