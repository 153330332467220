import React from 'react';
import Navigation from "../components/navigation/Navigation";
import PortfolioEntry from "../components/PortfolioEntry";

class Portfolio extends React.Component {

  portfolioEntries = () => {
    return (
        <div>
          <PortfolioEntry projectAsset={"/assets/oca-logo.png"}
                          projectLink={"https://www.osoriodecastroadvogados.pt/"}
                          project={"Osório de Castro e Associados - Law Firm"}
                          stack={"Frontend - VueJS 2, Bootstrap 4; CMS - Storyblok"}/>

          <PortfolioEntry projectAsset={"/assets/kitty.png"}
                          projectLink={"https://baby.rpmxh.pro"}
                          project={"Website for Baby Shower - Name Reveal Party"}
                          stack={"Frontend - VueJS 2, Bootstrap 4, D3.js; Backend - Python Flask"}/>
        </div>
    )
  }

  renderDesktop = () => {
    const rowClassName = "row justify-content-center align-items-center";

    return (
        <div className="container">
          <div className={rowClassName}>
            <div className="col-3">
              <h3> Logo </h3>
            </div>
            <div className="col-3">
              <h3> Project </h3>
            </div>
            <div className="col">
              <h3> Tech Stack </h3>
            </div>
          </div>
          {this.portfolioEntries()}
        </div>
    )
  }

  renderMobile() {
    return this.portfolioEntries();
  }


  render() {
    return (
        <div className="App">
          <Navigation/>
          <h1>Project Portfolio</h1>
          <hr/>
          <div className="d-none d-md-block">
            {this.renderDesktop()}
          </div>
          <div className="d-block d-md-none">
            {this.renderMobile()}
          </div>
        </div>
    );
  }
}

export default Portfolio;

