import React from 'react';
import FaunaDBThing from "../components/persistence/FaunaDBThing";

const FaunaDBDemo = () => {
  return (
      <div>
        <FaunaDBThing/>
      </div>
  );
}

export default FaunaDBDemo;
