import React from 'react';
import PaypalButton from "../components/payment/PaypalButton";

const PaypalButtonDemo = () => {

  const currency = "USD"
  const products = [
    {
      name: "product 1",
      price: 10.01,
      description: "product 1",
      img: process.env.PUBLIC_URL + '/assets/coin.jpg'
    }, {
      name: "product 2",
      price: 15,
      description: "product 2",
      img: process.env.PUBLIC_URL + '/assets/doge.jpg'
    }
  ]
  const orderedProducts = [
    {
      name: "product 1",
      price: 10.01,
      quantity: 2
    }, {
      name: "product 2",
      price: 15,
      quantity: 3
    }
  ]

  const renderedProducts = products.map((product, i) => {
    return <div className="col-3" key={i}>
      <img src={product.img} className="img-fluid" alt=""/>
      <h5>{product.description} for ${product.price}</h5>
    </div>
  })

  return (
      <div>
        <h2>Paypal Payment</h2>
        <div className="row justify-content-center align-items-center">
          {renderedProducts}
        </div>
        <PaypalButton products={orderedProducts} currency={currency}/>
      </div>
  );
}

export default PaypalButtonDemo;
