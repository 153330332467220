import './App.css';

import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Home from './pages/Home';
import Error from './pages/Error';
import Library from "./pages/Library";
import NvmvsHome from './pages/numus/NvmvsHome';
import NvmvsPrivacyPolicy from "./pages/numus/NvmvsPrivacyPolicy";
import NvmvsProducts from "./pages/numus/NvmvsProducts";
import NvmvsContacts from "./pages/numus/NvmvsContacts";
import Portfolio from "./pages/Portfolio";

class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <div>
            <Switch>
              <Route path="/" component={Home} exact/>
              <Route path="/portfolio" component={Portfolio}/>
              <Route path="/library" component={Library}/>
              <Route path="/nvmvs/privacy-policy" component={NvmvsPrivacyPolicy}/>
              <Route path="/nvmvs/products" component={NvmvsProducts}/>
              <Route path="/nvmvs/contacts" component={NvmvsContacts}/>
              <Route path="/nvmvs" component={NvmvsHome}/>
              <Route component={Error}/>
            </Switch>
          </div>
        </BrowserRouter>
    );
  }
}

export default App;
