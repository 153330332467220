import React from "react";

class NvmvsBanner extends React.Component {
  constructor(props) {
    super(props);
  }

  renderDesktop = () => {
    return (
        <div className="row">
          <img src={process.env.PUBLIC_URL + '/assets/nvmvs-banner.jpg'}
               alt=""
               className="img-fluid"/>
        </div>
    )
  }

  renderMobile() {
    return (
        <div className="row">
          <img src={process.env.PUBLIC_URL + '/assets/nvmvs-banner.jpg'}
               alt=""
               className="img-fluid"/>
        </div>
    )
  }

  render() {
    return (
        <div>
          <div className="d-none d-md-block">
            {this.renderDesktop()}
          </div>
          <div className="d-block d-md-none">
            {this.renderMobile()}
          </div>
        </div>
    );
  }
}

export default NvmvsBanner;

