import React from 'react';

import {NavLink} from 'react-router-dom';

const NvmvsNavigation = () => {
  return (
      <div>
        <nav className="navbar navbar-dark bg-dark navbar-expand-lg">
          <div className="container-fluid">
            <NavLink className="navbar-brand" to="/nvmvs">NVMVS</NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavNvmvs"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"/>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavNvmvs">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/nvmvs/products">Products</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/nvmvs/contacts">Contacts</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
  );
}

export default NvmvsNavigation;
