import React from 'react';
import NvmvsNavigation from "../../components/navigation/NvmvsNavigation";

const NvmvsPrivacyPolicy = () => {
  return (
      <div className="container">
        <NvmvsNavigation/>
        <h1>Privacy Policy</h1>
      </div>
  );
}

export default NvmvsPrivacyPolicy;
