import React from 'react';
import Navigation from "../components/navigation/Navigation";
import CustomToggle from "../components/experiments/CustomToggle";
import PaypalButtonDemo from "../demo/PaypalButtonDemo";
import FaunaDBDemo from "../demo/FaunaDBDemo";

const Library = () => {
  return (
      <div className="App">
        <Navigation/>
        <h1>Component library used in projects</h1>
        <hr/>
        <div>
          <h2>Custom Toggle Design</h2>
          <CustomToggle/>
        </div>
        <hr/>
        <PaypalButtonDemo/>
        <hr/>
        <div>
          <h2>FaunaDB Demo</h2>
          <FaunaDBDemo/>
        </div>
      </div>
  );
}

export default Library;
