import React from 'react';
import {Collection, Documents} from "faunadb";

const faunadb = require('faunadb'), q = faunadb.query;

class FaunaDBThing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      records: []
    };
  }

  componentDidMount() {
    this.fetchRecords();
  }

  fetchRecords = async () => {
    const client = new faunadb.Client({secret: process.env.REACT_APP_FAUNADB_ALL_READ_KEY});

    let queryResult = await client.query(
        q.Map(
            q.Paginate(Documents(Collection('Profile'))),
            q.Lambda(x => q.Get(x))
        )
    );

    this.setState({records: queryResult.data});
  }

  render() {
    const renderedRecords = this.state.records.map((record, i) => {
      return (
          <div key={i}>
            <h3> name -> {record.data.name} </h3>
            <h3> icon -> {record.data.icon} </h3>
          </div>
      )
    });

    return (
        <div>
          {(renderedRecords.length > 0 && renderedRecords) || (<h3>Unable to fetch data</h3>)}
        </div>
    );
  }
}

export default FaunaDBThing;
